import RejectIcon from "../../../../static/images/reffilPageIcons/RegectIcon.svg"
import s from "./rejectAnswer.module.scss"
import React, {useContext} from "react";
import RefillModalContext from "../../../../context/RefillModalContext";
import Button from "@mui/material/Button";
import {useSearchParams} from "react-router-dom";
const RejectAnswer = () => {
    const [searchParams] = useSearchParams()
    const payStatus = searchParams.has("status") && searchParams.get("status")
    const {setMode, rejectMessageList} = useContext(RefillModalContext)
  return (
      <div className={s.rejectAnswerBlock}>
          <div className={s.iconContainer}>
              <img src={RejectIcon} alt="RejectIcon"/>
              <span className={s.text}>Ошибка</span>
          </div>

              {
                  payStatus === "reject"
                      ? <span className={s.text}>Оплата не прошла, попробуйте еще раз</span>
                      :rejectMessageList.length > 0
                          ? rejectMessageList.map(textError => <span className={s.text}>{ textError }!</span>)
                          : <span className={s.text}> Что то пошло не так, попробуйте ещё раз.</span>

              }

          <Button
              sx={{
                  height: "40px",
                  background: "#fdba2f",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  color: "#fff !important",
                  "&:hover": {
                      background: "#fdba2f"
                  }
              }}
              onClick={() => setMode("step1")}
          >
              Назад
          </Button>
      </div>
  )
}

export default RejectAnswer