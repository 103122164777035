import CustomInput from "../../CustomInput/CustomInput";
import React, {useContext, useRef, useState} from "react";
import LoadFileIcon from "../../../../static/images/reffilPageIcons/loadFileIcon.svg"
import  s from "./index.module.scss"
import Button from "@mui/material/Button";
import RefillModalContext from "../../../../context/RefillModalContext";
import CustomSelect from "../../CustomSelect/CustomSelect";
import {selectOptionCompanyType} from "../../constRefill/stepTwoModalConst";
import useValidation from "../../../../Components/hooks/useValidation";
import Box from "@mui/material/Box";

const CreateAccStepTwo = () => {
    const {setMode, stepTwoFormData, setStepTwoFormData, typeSystem} = useContext(RefillModalContext)
    const { errorObj, validate, setErrorObj } = useValidation()
    const filePickerRef = useRef(null)

    const handleOnChange = (e) => {
        setStepTwoFormData({...stepTwoFormData, [e.target.name]: e.target.value})
    }
    const handlePick = () => {
      filePickerRef.current.click()
    }
    const fileOnChange = (e) => {
        if(e.target.files[0].size > (2 * 1024 * 1024)){
            setErrorObj({...errorObj, reference: "Размер файла не должен превышать 2MB."})
            return
        }

        const isError = validate({reference: e.target.files[0]})
        if(!isError){
            setStepTwoFormData({...stepTwoFormData, reference: e.target.files[0]})
            const {reference, ...rest} = errorObj
            setErrorObj({...rest})
        }else{
            setStepTwoFormData({...stepTwoFormData, reference: undefined})
        }
    }

    const handleClick = () => {
        const isError = validate(stepTwoFormData)
        if(!isError){
            dataLayer.push({
                "event": "formSubmit",
                "formId": "account_create_step_two",
                "form_data": {...stepTwoFormData, typeSystem}
            });
            setErrorObj({})
            setMode("step3")
        }
    }
  return(
      <div className={s.createAccBlock}>
          <h2 className={s.title}>Создание аккаунта</h2>
          <div className={s.inputContainer}>

              <CustomInput
                  width={320}
                  name="name"
                  value={stepTwoFormData.name || ""}
                  label="Название компании"
                  onBlur={validate}
                  onChange={handleOnChange}
                  helperText={errorObj.name}
                  error={!!errorObj.name}
              />

              <CustomSelect
                  name="company_type"
                  selectValue={stepTwoFormData.company_type}
                  selectData={selectOptionCompanyType}
                  label="Тип компании"
                  selectOnChange={handleOnChange}
                  onBlur={validate}
                  helperText={errorObj.company_type}
                  error={!!errorObj.company_type}
              />

              <CustomInput
                  width={320}
                  name="bin_number"
                  value={stepTwoFormData.bin_number || ""}
                  label="БИН"
                  onBlur={validate}
                  onChange={handleOnChange}
                  helperText={errorObj.bin_number}
                  error={!!errorObj.bin_number}
              />

              <div className={s.loadFileContainer}>
                  <Button onClick={handlePick} className={s.loadFileBtn}>
                      <input
                          ref={filePickerRef}
                          className={s.inputFile}
                          type="file"
                          accept="image/png, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          onChange={fileOnChange}
                      />
                      <img src={LoadFileIcon} alt="LoadFileIcon"/>
                  </Button>
                  <div className={s.loadFileTextContainer}>
                      <span className={s.loadFileText}>Загрузите свидетельство о регистрации компании или талон для ИП</span>
                      {
                          stepTwoFormData?.reference?.name &&
                          <span className={s.fileName}>{ stepTwoFormData.reference.name }</span>
                      }
                      {
                          errorObj.reference &&  <span className={s.errorMessage}>{ errorObj.reference }</span>
                      }
                  </div>
              </div>
          </div>

          <div className={s.btnContainer}>
              <Button
                  sx={{
                      "&:hover": {
                          background: "#fdba2f"
                      }
                  }}
                  onClick={()=> {setMode("step1")}}
                  className={s.btn}>
                  Назад
              </Button>
              <Button
                  onClick={handleClick}
                  sx={{
                      "&:hover": {
                          background: "#fdba2f"
                      }
                  }}
                  className={s.btn}>
                  Продолжить
              </Button>
          </div>

      </div>
  )
}

export default CreateAccStepTwo