export const initialFirstFormData = {
    ad_system: null,
    first_name : "",
    last_name : "",
    email : "",
    organization_type : null,
    promocode : "",
}

export const updateFormDataStepOne = (ad_system, formData) => {
    const updatedFormData = { ...formData, ad_system };
    if(ad_system === "Google Ads" || ad_system === "Yandex Direct"){
        const {business_manager_id, phone_number, sub_industry, industry, placement, ...rest} = updatedFormData
        return {...rest}
    } else if(ad_system === "TikTok"){
        const fields =  {phone_number: "", placement: "", sub_industry: null, industry: null,}
        delete updatedFormData.business_manager_id
        return {...updatedFormData, ...fields}
    }else if(ad_system === "Facebook") {
        const {phone_number, sub_industry, industry, placement, ...rest} = updatedFormData
        return {...rest, business_manager_id: ""}
    }
}




export const inputData = [
    {label: "Имя", name: "first_name", type: "text"},
    {label: "Фамилия", name: "last_name", type: "text"},
    {label: "Email", name: "email", type: "text"},
    {label: "Телефон", name: "phone_number", type: "number"},
    {label: "Сайт", name: "placement", type: "text"},
]

export const inputPsKz = {label: "Промокод от ps.kz", name: "promocode", type: "text"}

export const selectOptionOrganization = [
    {title:"Юридическое лицо", value: "Юр. лицо",},
    {title:"Физическое лицо", value: "Физ. лицо"}
]
export const selectOptionSystem = [
    {title: "Google", value: "Google Ads"},
    {title: "Yandex", value: "Yandex Direct"},
    {title: "TikTok", value: "TikTok"},
    {title: "Meta", value: "Facebook"},
]
