import styles from './index.module.scss';
import React, {useContext, useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Apartment';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import {tender_form_post} from '../../../api/FormsApi';
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import MessageContext from '../../../context/MessageProvider';
import {useTranslation} from "react-i18next";

export default function TenderForm({close}) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const {setMessage} = useContext(MessageContext);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (name != '' && phone != '' && email != '' && company != '' && checked != false) {
            setDisabled(false);
        } else {
            if (!disabled) {
                setDisabled(true);
            }
        }
    }, [name, phone, email, company, checked]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // dataLayer.push({
        //     'event': 'formSubmit',
        //     'form_id': 'form_submit_tender',
        //     'form_name': 'Пригласить на тендер'
        // });
        async function postData() {
            const response = await tender_form_post({name, company, email, phone});
            if (response.status === 200) {
                setMessage('Заявка успешно отправлена!');
                close();
            }
        }
        postData();
    };

    return (
        <Box id="form_submit_tender" name="Пригласить на тендер" component="form" onSubmit={handleSubmit} className={`${styles.tender_form}`}>
            {/*<input  type="hidden" name="note" value="form_submit_tender" />*/}
            <h2>
                {
                    t("invite_tender_modal.title")
                }
            </h2>
            <p className={'font-14 w400'} style={{margin: '24px 0 24px 0', textAlign: 'left'}}>
                {
                    t("invite_tender_modal.sub_title")
                }
            </p>
            <TextField
                id="name"
                label={`${t("invite_tender_modal.input1")}`}
                variant="outlined"
                margin="normal"
                className={'form_field'}
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                    endAdornment: <PersonIcon sx={{color: '#FDBA2F'}} />,
                }}
            />
            <TextField
                id="email"
                label="E-mail"
                variant="outlined"
                margin="normal"
                type="email"
                className={'form_field'}
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                    endAdornment: <EmailIcon sx={{color: '#FDBA2F'}} />,
                }}
            />
            <TextField
                id="company"
                label={`${t("invite_tender_modal.input2")}`}
                variant="outlined"
                margin="normal"
                className={'form_field'}
                fullWidth
                required
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                InputProps={{
                    endAdornment: <BusinessIcon sx={{color: '#FDBA2F'}} />,
                }}
            />
            <TextField
                id="phone"
                label={`${t("invite_tender_modal.input3")}`}
                variant="outlined"
                margin="normal"
                type="tel"
                className={'form_field'}
                fullWidth
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                InputProps={{
                    endAdornment: <PhoneIcon sx={{color: '#FDBA2F'}} />,
                }}
            />
            <FormGroup sx={{mt: 3}}>
                <FormControlLabel
                    className={'default_checkbox'}
                    control={
                        <Checkbox
                            sx={{
                                color: '#000',
                                '& .MuiSvgIcon-root': {fontSize: 24},
                                '&.Mui-checked': {
                                    color: '#000',
                                },
                            }}
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                        />
                    }
                    label={`${t("invite_tender_modal.checkbox")}`}
                />
            </FormGroup>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button
                    variant="filled"
                    color="primary"
                    type="submit"
                    className={'button-filled'}
                    disabled={disabled}
                    sx={{
                        mt: 3,
                        alignSelf: 'center',
                        display: 'flex',
                        justifySelf: 'center',
                        pointerEvents: checked ? 'initial' : 'none',
                    }}
                >
                    {
                        t("invite_tender_modal.send_request_btn")
                    }
                </Button>
            </div>
        </Box>
    );
}
