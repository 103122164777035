import React, {useState} from "react"
import { MenuItem, TextField } from "@mui/material"

export const CustomSelect = (props) => {
    const { selectData, placeholder, selectOnChange, name, selectValue, label } = props
    const [active, setActive] = useState(false)
    return(
        <TextField
            select
            value={selectValue}
            name={name}
            label={label}
            error={props.error}
            helperText={props.helperText}
            disabled={selectData.length === 0}
            onChange={(e)=> {
                selectOnChange(e)
                setActive(true)
            }}
            onBlur={(e) => {
                props.onBlur({[props.name]: e.target.value});
            }}
            sx={{
                // maxWidth: "320px",
                // width: "calc(50% - 8px)",
                width: {
                    xs: "100%",   // Мобильные устройства
                    md: "calc(50% - 8px)",    // Ноутбуки
                },
                boxShadow: 'none',
                ".MuiPaper-root":{
                    "& .MuiList-root": {
                        maxHeight: "400px",
                    },
                },

                '& .MuiInputLabel-root': {
                    fontFamily: "Montserrat",
                    color: 'rgba(0, 0, 0, 0.4)', // цвет обычной метки
                },
                '& .MuiInputLabel-root.Mui-focused': {
                    color: '#fdba2f', // цвет метки в состоянии focused
                },

                "& .MuiSelect-select": {
                    color: active ? '#000' : '#666666',
                    fontFamily:"Montserrat",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#fdba2f !important',
                },
                "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fdba2f !important",
                    borderWidth: "1px !important"
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${props.error ? "red" : "#ECECEF"} !important`,
                }

            }}
            inputProps={{
                style: {
                    border: 'none',
                },
            }}
        >
            {/*<MenuItem sx={{fontFamily: "Montserrat"}} disabled value="empty">*/}
            {/*    {*/}
            {/*        placeholder*/}
            {/*    }*/}
            {/*</MenuItem>*/}
            {
                selectData.map((elOption, index) => {
                    return(
                        <MenuItem
                            sx={{whiteSpace: 'pre-wrap'}}
                            key={index}
                            value={elOption.value}
                        >
                            {
                                elOption.title
                            }
                        </MenuItem>
                    )
                })
            }

        </TextField>
    )
}

export default CustomSelect
