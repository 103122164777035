import s from "./customInput.module.scss";
import React from "react";
import TextField from "@mui/material/TextField";

const CustomInput = (props) => {
    return(
        <TextField
            inputProps={{
                sx:{
                    '&::placeholder': {
                        color: '#666666',
                        opacity: 1, // otherwise firefox shows a lighter color
                    },
                }
            }}
            sx={{
                width: {
                    xs: "100%",   // Мобильные устройства
                    md: "calc(50% - 8px)",    // Ноутбуки
                },
                boxShadow: 'none',
                '& .MuiInputLabel-root': {
                    fontFamily: "Montserrat",
                    color: props.error ? "red" : "rgba(0, 0, 0, 0.4)", // цвет обычной метки
                },
                '& .MuiInputLabel-root.Mui-focused': {
                    color: "#fdba2f", // цвет метки в состоянии focused
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor:'#fdba2f', // цвет рамки при hover
                },
                '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#ECECEF", // цвет рамки при hover в disabled
                },
                '& .MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: "#ECECEF", // цвет рамки при hover в disabled
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: "1px solid #fdba2f", // цвет рамки в состоянии focused
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${props.error ? "red" : "#ECECEF"}`, // цвет обычной рамки

                },
                '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    border: "1px solid #fdba2f" , // цвет рамки при наведении
                }
            }}
            error={props.error}
            helperText={props.helperText}
            {...props}
            onBlur={(e) => {
                props.onChange(e);
                props.onBlur( {[props.name]: e.target.value});
            }}
        />
    )
}

export default CustomInput