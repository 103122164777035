import FullFiledIcon from "../../../../static/images/reffilPageIcons/FullFiledIcon.svg"
import s from "./fullfiledAnswer.module.scss"
import {useSearchParams} from "react-router-dom";
const FullfiledAnswer = () => {

    const [searchParams] = useSearchParams()
    const payStatus = searchParams.has("status") && searchParams.get("status")

  return (
      <div className={s.fullfiledAnswerBlock}>
          <div className={s.iconContainer}>
              <img src={FullFiledIcon} alt="fullFiledIcon"/>
              <span className={s.text}>Успешно</span>
          </div>
          <span className={s.text}>
             {
                 payStatus === "fulfilled"
                     ? "Оплата прошла успешно"
                     : "Заявка успешно создана."
             }
          </span>
      </div>
  )
}

export default FullfiledAnswer