import CustomInput from "../../CustomInput/CustomInput";
import React, {useContext, useState} from "react";
import  s from "./index.module.scss"
import Button from "@mui/material/Button";
import RefillModalContext from "../../../../context/RefillModalContext";
import {calculate} from "../../constRefill/calc";
import {create_application, get_redirect_url} from "../../../../api/RefillApi";
import useValidation from "../../../../Components/hooks/useValidation";
import {getListError} from "../../constRefill/parserErrorData";
import {formatNumber} from "../../constRefill/const";
import {descriptionRefillAccStepThree} from "../../constRefill/stepThreeModalConst";
import Box from "@mui/material/Box";

const CreateAccStepThree = () => {
    const {typeOrganization, stepOneFormData, stepTwoFormData, setMode, setRejectMessageList, typeSystem} = useContext(RefillModalContext)
    const {organization_type, ad_system: typeAdSystem } = stepOneFormData
    const {company_type } = stepTwoFormData
    const initialCurrentSumData = {
        first_pay_sum: "",
        sum_refill: "",
        cashback_commission: {
            "Google Ads": "Комиcсия 5%",
            "Yandex Direct": "Кэшбек 10%",
            "TikTok": "Кэшбек 5%",
            "Facebook": "Комиcсия 5%",
        },
        nds: "12%"
    }
    const [currentSumData, setCurrentSumData] =useState(initialCurrentSumData)
    const [formData, setFormData] = useState({first_pay_sum: ""})
    const is_TOO_Company_juridical_organization_type = company_type === "ТОО" && organization_type === "Юр. лицо"
    const extraShemValidation = {
        first_pay_sum : {
            regex: typeAdSystem === "Yandex Direct"
                ? is_TOO_Company_juridical_organization_type
                    ? "^(?:(?:1[5-9] \\d{3}([,\\.]\\d{1,2})?)|(?:[2-9]\\d{1} \\d{3}([,\\.]\\d{1,2})?)|(?:\\d{3} \\d{3}([,\\.]\\d{1,2})?)|(?:\\d{1,3} \\d{3} \\d{3}([,\\.]\\d{1,2})?)|(?:\\d{1,3} \\d{3} \\d{3}([,\\.]\\d{1,2})?))$"
                    : "^(?:(?:1[5-9] \\d{3}([,\\.]\\d{1,2})?)|(?:[2-9]\\d{1} \\d{3}([,\\.]\\d{1,2})?)|(:?[1-4]\\d{2} \\d{3}([,\\.]\\d{1,2})?)|500 000|500 000.0|500 000.00)$"
                : is_TOO_Company_juridical_organization_type
                    ? "^(?:(?:[5-9]\\d{1} \\d{3}([,\\.]\\d{1,2})?)|(?:\\d{3} \\d{3}([,\\.]\\d{1,2})?)|(?:\\d{1,3} \\d{3} \\d{3}([,\\.]\\d{1,2})?))$"
                    : "^(?:(?:[5-9]\\d{1} \\d{3}([,\\.]\\d{1,2})?)|(?:[1-4]\\d{2} \\d{3}([,\\.]\\d{1,2})?)|500 000|500 000.0|500 000.00)$",
            errorMessage: typeAdSystem === "Yandex Direct"
                ? is_TOO_Company_juridical_organization_type
                    ? "Сумма оплаты не должна быть меньше 15000"
                    : "Сумма оплаты не должна быть меньше 15000 и не привышать 500000"
                :
                is_TOO_Company_juridical_organization_type
                    ? "Сумма оплаты не должна быть меньше 50000"
                    : "Сумма оплаты не должна быть меньше 50000 и не привышать 500000"
        }
    }

    const { errorObj, validate, setErrorObj } = useValidation(extraShemValidation)
    const handleSubmit = async (e) => {
        e.preventDefault()
        const isError = validate(formData)
        if(!isError) {
            dataLayer.push({
                "event": "formSubmit",
                "formId": "account_create_step_three",
                "form_data": {...formData, typeSystem}
            });
            setErrorObj({})
            const payLoad = {
                ...formData,
                first_pay_sum: formData.first_pay_sum.replace(" ", ""),
                ...stepOneFormData,
                ...(
                    organization_type === "Юр. лицо"
                        ? stepTwoFormData
                        : {}
                )
            }
            const systems = {
                "Google Ads": "google",
                "Yandex Direct": "yandex",
                "TikTok": "tiktok",
                "Facebook": "facebook",
            }
            setMode("loading")
            try {
                const resCreateApp = await create_application({system: systems[typeAdSystem], payLoad})
                const {ad_system: system_name, id: application_id, company_type} = resCreateApp.data
                const resGetRedirectUrl =  await get_redirect_url({system_name, application_id})
                setMode("fulfilled")
                if(company_type === "ИП" || company_type === null){
                    const {url} = resGetRedirectUrl.data
                    console.log("freedom Url",url)
                    window.location.href = url
                }

            }catch (error){
                setRejectMessageList(getListError(error.response.data))
                setMode("reject")
            }
        }
    }

    const handleCLickBack = () => {
        typeOrganization === "Юр. лицо" ? setMode("step2") : setMode("step1")
    }

    const handleChange = (e) => {
        let temResultCalculation
        switch (typeAdSystem) {
            case "Yandex Direct" :{
                const data = {
                    type_calculation:"cashback",
                    type_input: e.target.name,
                    main_input:"first_pay_sum",
                    x: e.target.value.replaceAll(/[^\d.,]/g, ''),
                    y: 1.1
                }
                temResultCalculation = calculate(data)
                break
            }
            case "TikTok" :{
                const data = {
                    type_calculation:"cashback",
                    type_input: e.target.name,
                    main_input:"first_pay_sum",
                    x: e.target.value.replaceAll(/[^\d.,]/g, ''),
                    y: 1.05
                }
                temResultCalculation = calculate(data)
                break
            }
            default: {
                const data = {
                    type_calculation:"commission",
                    type_input: e.target.name,
                    main_input:"first_pay_sum",
                    x: e.target.value.replaceAll(/[^\d.,]/g, ''),
                    y: 1.05
                }
                temResultCalculation = calculate(data)
                break
            }
        }
        let resultCalculation = {}
        for (const key in temResultCalculation) {
            resultCalculation = {...resultCalculation, [key]: formatNumber(temResultCalculation[key])}
        }
        setCurrentSumData({...currentSumData, [e.target.name]: formatNumber(e.target.value), ...resultCalculation})
        setFormData({...formData, first_pay_sum: currentSumData.first_pay_sum})
    }

  return(
      <Box
          id="form_submit_create_account_step_three"
          name="Создать аккаунт"
          component="form"
          onSubmit={handleSubmit}
          className={s.createAccBlock}
      >
          <h2 className={s.title}>Пополните рекламный аккаунт {descriptionRefillAccStepThree[typeAdSystem].title}</h2>
          <p className={s.descriptionRefill}>
              {descriptionRefillAccStepThree[typeAdSystem].description + " "}
              <span className={s.subDescriptionRefill}>Оплаченная сумма будет уже зачислена.</span>
          </p>
          <div className={s.inputContainer}>
              <CustomInput
                  width={320}
                  type="text"
                  label="Сумма к оплате"
                  name="first_pay_sum"
                  disabled={formData.system_name === null}
                  value={currentSumData.first_pay_sum}
                  onChange={handleChange}
                  onBlur={validate}
                  helperText={errorObj.first_pay_sum}
                  error={!!errorObj.first_pay_sum}
              />

              <CustomInput
                  type="text"
                  label="НДС"
                  disabled
                  value={currentSumData.sum_with_NDS || ""}
                  onBlur={()=>{}}
              />

              <CustomInput
                  type="text"
                  label={currentSumData.cashback_commission[typeAdSystem] || "Комиссия / Кэшбек"}
                  disabled
                  value={currentSumData.sum_cashback_commission || ""}
                  onBlur={()=>{}}
              />

              <CustomInput
                  width={320}
                  type="text"
                  label="Сумма пополнения"
                  name="sum_refill"
                  disabled={formData.system_name === "empty"}
                  value={currentSumData.sum_refill}
                  onChange={handleChange}
                  onBlur={()=>{}}
              />
          </div>

          {
              typeAdSystem !== "Yandex Direct"
                  ? <span className={s.calcHelperText}>Зачисление на аккаунт будет в USD, курс конвертациификсируется в момент поступления денег по банку АО «Народный Банк Казахстана».</span>
                  : null
          }

          <div className={s.btnContainer}>
                <Button
                    sx={{
                        "&:hover": {
                            background: "#fdba2f"
                        }
                    }}
                    onClick={handleCLickBack}
                    className={s.btn}
                >
                    Назад
                </Button>
                <Button
                    type="submit"
                    sx={{
                        "&:hover": {
                            background: "#fdba2f"
                        },
                    }}
                    className={s.btn}
                >
                    Создать
                </Button>
          </div>

      </Box>
  )
}

export default CreateAccStepThree


